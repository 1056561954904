import styled from 'styled-components';

export const JobDescriptionWrapper = styled.div``;

export const JobDescriptionTitle = styled.h3`
  color: ${({ theme }) => theme.color.text.primary};
  font-size: 36px;
  text-transform: uppercase;
  margin: 40px 0;
  font-weight: 700;
  line-height: 1.1;
`;

export const JobDescriptionText = styled.p`
  color: ${({ theme }) => theme.color.text.secondary};
  margin: 0 0 10px;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 20px;
  a:link {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
  a:hover {
    opacity: 0.6;
  }
  a:visited {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
`;
