import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { JobDescriptionText, JobDescriptionTitle, JobDescriptionWrapper } from './styled';

interface Props {
  header: string;
  text: string;
}

const JobDescriptionItem = ({ header, text }: Props) => {
  return (
    <JobDescriptionWrapper>
      <JobDescriptionTitle>{header}</JobDescriptionTitle>
      <JobDescriptionText>{ReactHtmlParser(text)}</JobDescriptionText>
    </JobDescriptionWrapper>
  );
};

export default JobDescriptionItem;
