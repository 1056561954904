import { RedLink } from 'components/CommonComponents/RedButtons';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { Separator } from 'components/PageAboutUs/styled';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { JobDescriptionColumn, JobDescriptionContainer, JobIntroContainer, JobParagraph, JobTitle } from '../styled';
import JobDescriptionItem from './JobDescriptionItem';

interface Props {
  title: string;
  text: string;
  linkText: string;
  description: {
    header: string;
    text: string;
  }[];
}

const JobContent = ({ linkText, title, text, description }: Props) => {
  return (
    <>
      <StyledSection>
        <JobIntroContainer>
          <JobTitle>{title}</JobTitle>
          <JobParagraph>{ReactHtmlParser(text)}</JobParagraph>
        </JobIntroContainer>
        <JobDescriptionContainer>
          <JobDescriptionColumn>
            <JobDescriptionItem header={description[0].header} text={description[0].text} />
            <JobDescriptionItem header={description[2].header} text={description[2].text} />
          </JobDescriptionColumn>
          <JobDescriptionColumn>
            <Separator />
            <JobDescriptionItem header={description[1].header} text={description[1].text} />
          </JobDescriptionColumn>
        </JobDescriptionContainer>
        <Separator />
        <RedLink to={'/aboutUs'} text={linkText}></RedLink>
        <Separator />
      </StyledSection>
    </>
  );
};

export default JobContent;
