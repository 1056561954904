import Footer from 'components/CommonComponents/Footer';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import SEO from 'components/CommonComponents/SEO';
import Position from 'components/PageCareer/Position';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';

const pageQuery = graphql`
  {
    careerYaml {
      backgroundImage
      linkText {
        cs
      }
      interested {
        header {
          cs
        }
        text {
          cs
        }
      }
    }
    allPositionsYaml {
      nodes {
        slug
        header {
          cs
        }
        sneak {
          cs
        }
        text {
          cs
        }
        description {
          header {
            cs
          }
          text {
            cs
          }
        }
      }
    }
  }
`;

const Job = ({ pageContext }: any) => {
  const locale = 'cs';
  const { positionSlug } = pageContext;

  const data = useStaticQuery(pageQuery);
  const siteData = data.allPositionsYaml.nodes.find((a: any) => a.slug === positionSlug);

  useEffect(() => {
    !siteData && navigate('/career', { replace: true });
  }, [siteData]);

  if (!siteData) {
    return <></>;
  }

  const description = siteData.description.map((a: any) => ({ header: a.header[locale], text: a.text[locale] }));

  return (
    <>
      <SEO title={siteData.header[locale]} />
      <GlobalStyle />

      <NavigationBar />
      <Position
        headerText={siteData.header[locale]}
        image={data.careerYaml.backgroundImage}
        interestedHeader={data.careerYaml.interested.header[locale]}
        interestedText={data.careerYaml.interested.text[locale]}
        linkText={data.careerYaml.linkText[locale]}
        positionDescription={description}
        positionText={siteData.text[locale]}
      />
      <Footer />
    </>
  );
};

export default Job;
