import styled from 'styled-components';

export const JobIntroContainer = styled.div`
  width: 100%;
  margin: 150px 0 100px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: 50%;
    align-self: flex-start;
  }
`;

export const JobTitle = styled.h1`
  color: ${({ theme }) => theme.color.text.primary};
  font-size: 40px;
  letter-spacing: 6px;
  margin: 40px 0;
  text-transform: uppercase;
  font-weight: 700;
  @media screen and (min-width: ${({ theme }) => theme.layout.sm}px) {
    font-size: 80px;
  }
`;

export const JobParagraph = styled.p`
  color: ${({ theme }) => theme.color.text.secondary};
  margin: 0 0 10px;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 20px;
  strong {
    color: ${({ theme }) => theme.color.text.primary};
    font-weight: bold;
  }
`;

export const JobDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    flex-direction: row;
  }
`;

export const JobDescriptionColumn = styled.div`
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: 46%;
    :first-child {
      margin: 0 auto 0 0;
    }
  }
`;

export const Separator = styled.div`
  margin-bottom: 150px;
`;

export const InterestedHeader = styled.h2`
  font-size: 40px;
  margin: 40px 0;
  color: ${({ theme }) => theme.color.text.primary};
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.1;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 64px;
  }
`;
