import CareerForm from 'components/CommonComponents/Forms/CareerForm';
import { SectionWithBackground, StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { Separator } from 'components/PageAboutUs/styled';
import React from 'react';
import JobContent from './JobContent';
import { InterestedHeader, JobParagraph } from './styled';

interface Props {
  image: string;
  linkText: string;
  headerText: string;
  positionText: string;
  interestedHeader: string;
  interestedText: string;
  positionDescription: {
    header: string;
    text: string;
  }[];
}

const Position = ({ image, linkText, positionText, headerText, interestedText, positionDescription, interestedHeader }: Props) => {
  return (
    <SectionWithBackground as="main" imageLink={image}>
      <JobContent title={headerText} text={positionText} linkText={linkText} description={positionDescription} />
      <StyledSection>
        <InterestedHeader>{interestedHeader}</InterestedHeader>
        <JobParagraph>{interestedText}</JobParagraph>
        <Separator />
        <CareerForm />
      </StyledSection>
    </SectionWithBackground>
  );
};

export default Position;
